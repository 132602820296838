import { useEffect } from "react";
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// components
import NavBar from './sections/NavBar';
import HeroSection from './sections/Hero';
import LogoCloud from './sections/LogoCloud';
import { logosContent, logosHeader } from "./content/logos";
import Challenges from './sections/Challenges';
import { challengesContent, challengesHeader } from "./content/challenges";
import Benefits from "./sections/Benefits";
import { benefitsContent, benefitsHeader } from "./content/benefits";
import About from "./sections/About";
import { aboutContent, aboutHeader } from "./content/about";
import Testimonials from "./sections/Testimonials";
import { testimonialsContent, testimonialsHeader } from "./content/testimonials";

import Footer from "./sections/Footer";
import Section from "./Section";
import { heroContent, navigation } from "./content/hero";


const contentSections = [
  { component: Challenges, header: challengesHeader, content: challengesContent, bgColor: "white" },
  { component: Benefits, header: benefitsHeader, content: benefitsContent, bgColor: "secondary-100" },
  { component: About, header: aboutHeader, content: aboutContent, bgColor: "white" },
  { component: Testimonials, header: testimonialsHeader, content: testimonialsContent, bgColor: "white" },
]

function App() {
  useEffect(() => {
    AOS.init({ duration: 600 });
  }, [])
  return (
    <div data-aos-easing="ease" data-aos-delay="0" className="scroll-smooth">
      <div id="background-image" className="bg-cover bg-center bg-[linear-gradient(to_top,rgba(30,41,59,1.0),rgba(148,163,184,0.0)),url('../public/images/hero_img_francois.jpg')]">
      </div>
      <div>
        <NavBar content={navigation} contact_email={heroContent.contact_email} />
        <HeroSection content={heroContent} />
      </div>
      <LogoCloud content={logosContent} header={logosHeader} />
      {contentSections.map((sectionContent) => (
        <Section {...sectionContent.header} bgColor={sectionContent.bgColor}>
          <sectionContent.component content={sectionContent.content} />
        </Section>
      ))
      }
      <Footer />
    </div>
  );
}

export default App;
