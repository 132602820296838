export const aboutHeader = {
    surtitle: "About me",
    title: "Extensive leadership experience with rigorous coaching credentials",
    description: "",
    to_link: "about"
}

// each line will be a separate paragraph
export const aboutContent = [
    {
        section_title: "Extensive leadership experience",
        section_text:
            `I have 25 years of leadership experience in international growth businesses, including 10 years at Mars, 9 years at eBay and 3 years at a PE-backed media business for which I facilitated a successful exit. 
I have therefore ample experience of a variety of leadership experiences in complex and fast moving environments. I have accumulated over the years plenty of mental models and frameworks that have helped me navigate complexity.
`},
    {
        section_title: "Rigorous coaching credentials",
        section_text: `Since 2018, I have built a coaching practice with CEOs/Founders of early-stage businesses and senior executives of fast-growing scale ups and corporates. 
I am certified with the International Coaching Federation (Associate Certified Coach). I have trained with the highly reputable Academy of Executive Coaching (Practitioner Diploma in Executive Coaching, 2019). I am qualified to run personality assessments with both Hogan and Praditus (both self-assessment and 360 feedback). 
I am also a co-founder of Step Change,a leadership development practice that drives accelerated positive change to teams.
`},
    {
        section_title: "Education and Interests",
        section_text:
            `I have graduated from HEC Paris (Master in Management, 1994), London Business School (Executive MBA, 2003) and Birkbeck College, London University (Masters in Organisational Psychology, 2022). 

I have written about the impact of remote working on leadership in start-ups and my current research focuses on cross-team collaboration.
`}
] 
