import { AcademicCapIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline'


export const challengesHeader = {
    surtitle: "WHY REASONANCE?",
    title: `We spend most of our life working. Everyone deserves to thrive at work. Here are the most common obstacles leaders have to navigate in order to flourish:`,
    description: "",
    to_link: "challenges"
}

export const challengesContent = [
    {
        name: "As an individual leader",
        description:
            `* Difficult, nuanced and important decisions need to be made urgently but you can’t quite get to decide which way to go and you feel you are losing time 
            -> Frictions with colleagues prevent you from collaborating effectively and geting the results you want
            -> You feel isolated. There is no one you can really confide to.
* Days of immense hope and sense of achievement are often followed by darker days of doubt and lack of self-confidence
* Difficult, nuanced and important decisions need to be made urgently but you can’t quite get to decide which way to go and you feel you are losing time
* You have succeeded so far thanks to your natural strengths but you sense that you need to broaden your leadership style to accommodate further growth and complexity
`,
        icon: AcademicCapIcon,
    },
    {
        name: 'As a leader managing teams',
        description:
            `* Your team find it more difficult to navigate the  increasingly complex organisation and get stuff done in that environment
* It feels like your team is just a group of individuals fighting their corner. The level of collaboration is sub-optimal.
* Your team are in a pivotal phase of their career when they need to move from experts to enablers, from individual contributors to  leaders, from doers to influencers
* Your team struggle to project themselves into the organisation and establish a credible career path there
* Your team  lack the self- confidence and assertiveness to cut through and apply their strengths in a productive way in the organisation
`,
        icon: BuildingLibraryIcon,
    }
]
