import { ArrowDownIcon } from '@heroicons/react/24/outline'

export default function HeroSection(props: any) {
    return (
        <main>
            <div className="relative px-6 lg:px-8 filter-none">
                <div className="mx-auto max-w-3xl pt-24 pb-24 sm:pt-36 sm:pb-28">
                    <div className="pb-8 flex gap-x-4 justify-center">
                        <div className='backdrop-blur-md bg-white/25 p-5 rounded-2xl'>
                            <img className="h-32 pl-1 mx-auto" src="logo_reasonance_transparent.png" alt="" />
                            <p className="mt-3 text-center font-bold text-2xl text-secondary-700">Reasonance</p>
                        </div>
                    </div>
                    <div>
                        <h1 className="text-4xl font-bold text-white tracking-tight text-center sm:text-6xl">
                            {props.content.title}
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-primary-200 text-center">
                            {props.content.subtitle}
                        </p>
                    </div>
                </div>
                <div className="pb-8 flex gap-x-4 justify-center">
                    <ArrowDownIcon className="h-6 w-6 text-white/75" aria-hidden="true" />
                </div>
            </div>
        </main>
    )
} 