export const logosHeader = {
    title: "Trusted by"
}

export const logosContent = [
    {
        name: "Google",
        logo_name: "google",
    },
    {
        name: "Deliveroo",
        logo_name: "deliveroo",
    },
    {
        name: "Ebay",
        logo_name: "ebay",
    },
    {
        name: "Société Générale",
        logo_name: "societe-generale",
    },
    {
        name: "Unmade",
        logo_name: "unmade",
    },
    {
        name: "Story Terrace",
        logo_name: "storyterrace",
    },
    {
        name: "Memrise",
        logo_name: "memrise",
    },
    {
        name: "Babylon Health",
        logo_name: "babylon",
    },
    {
        name: "Ziflow",
        logo_name: "ziflow",
    },
    {
        name: "Amazon",
        logo_name: "amazon",
    },
    {
        name: "Nurole",
        logo_name: "nurole",
    },
    {
        name: "Stashbee",
        logo_name: "stashbee",
    },
]
