import { BoltIcon, UsersIcon } from '@heroicons/react/24/outline'


export const benefitsHeader = {
    surtitle: "Solutions",
    title: "How can I help?",
    description: "I have a rather unusual combination of extensive leadership experience and rigorous coaching credentials, all applied in the context of fast-growing digital businesses. This has equipped me with the context and tools to support my clients achieve their objectives.",
    to_link: "solutions"
}

export const benefitsContent = [
    {
        name: "HOW DOES IT WORK?",
        description:
            `Based on an initial conversation, I will devise a programme targeted at your specific needs.  This could involve:  
- An accelerated coaching booster programme of a few sessions that focuses on the most immediate development opportunities
- A more comprehensive and extended programme of support over 6 months that includes an initial professional personality assessment and regular interactions to shift behaviours and mindsets that are harder to change quickly
- An on-going support with regular sessions that address key issues as they arise. This can include advisory input into strategic or operational issues or coaching support on team and individuals.
- I can also intervene as a mediator to rebuild a relationship that has suffered at work
`,
        icon: BoltIcon,
    },
    {
        name: "HOW DO I COACH AND MENTOR?",
        description: `- I am both supportive and challenging. I will listen with undivided attention and not judge, but I will also point out the bullshit.
- I can coach by listening and asking powerful questions, but I can also mentor by sharing perspective and advice based on personal experience.
- After a few coaching sessions, past clients have said that they feel more self-confident, they understand how to best position themselves in their organisational systems, have improved the quality of their collaboration with important stakeholders and have adopted new mindsets and behaviours that help them thrive further at work.
`,
        icon: UsersIcon,
    }
]
