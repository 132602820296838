export const navigation = [
    { name: "Why Reasonance?", link_to: "challenges" },
    { name: "How I can help?", link_to: "solutions" },
    { name: "About", link_to: "about" },
    { name: "Testimonials", link_to: "testimonials" },
]

export const heroContent = {
    title: "Thrive at work",
    subtitle: "Executive coaching and mentoring that help leaders flourish in their organisational system",
    left_button_text: "Read more",
    right_button_text: "Contact",
    contact_email: "francois@reasonance.co.uk"
}
