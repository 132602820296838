import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

export default function Challenges(props: any) {
    return (
        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
            <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
                {props.content.map((feature: any, index: number) => (
                    <div key={feature.name} data-aos="fade-in" data-aos-delay={50 * index} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                        <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-primary-500 text-white sm:shrink-0">
                            <feature.icon className="h-8 w-8" aria-hidden="true" />
                        </div>
                        <div className="sm:min-w-0 sm:flex-1">
                            <p className="text-lg font-semibold leading-8 text-secondary-900">{feature.name}</p>
                            <ReactMarkdown className="mt-2 text-base leading-7 text-secondary-600 [&>p]:mt-5 [&>ul]:ml-3 [&>ul]:mt-2 [&>ul>li]:mt-1" remarkPlugins={[remarkBreaks]} >{feature.description}</ReactMarkdown>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}