import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";


export default function Section(props: any) {
    return (
        <div className={`bg-${props.bgColor} py-10 sm:py-24 lg:py-24`} id={props.to_link}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                {props.surtitle === "" ?
                    (
                        <div data-aos="fade-in" className="sm:text-center">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight text-secondary-900 sm:text-4xl">{props.title}</h2>
                            <ReactMarkdown className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-secondary-600 [&>p]:mt-5 [&>ul]:ml-3 [&>ul]:mt-2 [&>ul>li]:mt-1" remarkPlugins={[remarkBreaks]} >{props.description}</ReactMarkdown>
                        </div>
                    )
                    :
                    (
                        <div data-aos="fade-in" className="sm:text-center">
                            <h2 className="text-lg font-semibold leading-8 text-primary-600">{props.surtitle}</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-secondary-900 sm:text-4xl">{props.title}</p>
                            <ReactMarkdown className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-secondary-600 [&>p]:mt-5 [&>ul]:ml-3 [&>ul]:mt-2 [&>ul>li]:mt-1" remarkPlugins={[remarkBreaks]} >{props.description}</ReactMarkdown>
                        </div>
                    )
                }
                {props.children}
            </div>
        </div >
    )
}