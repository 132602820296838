export default function Footer() {
    return (
        <footer className="p-4 bg-white sm:p-6">
            <hr className="my-6 border-secondary-200 sm:mx-autolg:my-8" />
            <div className="grid place-items-center">
                <span className="text-sm text-secondary-500">© {new Date().getFullYear()} <a href="/" className="hover:underline">Reasonance</a>. All Rights Reserved.
                </span>
            </div>
        </footer>
    )
}