export default function LogoCloud(props: any) {
    return (
        <div className="bg-secondary-100 py-10 sm:py-12 lg:py-16" id="logo-cloud">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div data-aos="fade" data-aos-easing="ease-in" className="text-center">
                    <h2 className="text-lg font-semibold leading-8 text-secondary-500">{props.header.title}</h2>
                </div>

                <div className="mt-10 max-w-lg sm:mx-auto md:max-w-3xl">
                    <div className="grid grid-cols-2 gap-y-6 md:grid-cols-4 md:gap-x-4">
                        {props.content.map((company: any, index: number) => (
                            <div key={company.name} className="">
                                <img data-aos="fade-in" data-aos-delay={index * 50} className="h-12
                                 mx-auto object-contain" src={`/logos/${company.logo_name}@2x.png`} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}