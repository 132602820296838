import { UserPlusIcon } from "@heroicons/react/24/outline"
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

export default function About(props: any) {
    return (
        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
            <div data-aos="fade-in" data-aos-easing="ease" className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12">
                <div className="text-center mt-2">
                    <img className="border-primary-600 h-72 rounded-lg mx-auto object-contain ml-0 md:m-auto" src="images/portrait.png" alt="portrait of François Coumeau" />
                    <p className="text-left mt-5 font-semibold text-lg ml-0 md:text-center">François Coumau</p>
                    <p className="text-left font-light italic text-lg ml-0 md:text-center">Director and Founder</p>
                    <a className="mt-5 flex justify-start md:justify-center text-blue-600 hover:text-blue-800" href="https://www.linkedin.com/in/francoiscoumau/" target="_blank">
                        <UserPlusIcon className="h-5 w-5" aria-hidden="true" />
                        <p className="ml-2">
                            LinkedIn
                        </p>
                    </a>
                </div>
                <div className="">
                    {props.content.map((paragraph: any, index: any) =>
                        <div data-aos="fade-in">
                            <p className="text-lg font-bold text-secondary-800 ">{paragraph.section_title}</p>
                            <ReactMarkdown key={index} className="text-secondary-600 mt-1 mb-5 [&>p]:mb-5 [&>ul]:ml-3 [&>ul]:mt-2 [&>ul>li]:mt-1" remarkPlugins={[remarkBreaks]} >{paragraph.section_text}</ReactMarkdown>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
