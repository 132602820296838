import React, { useState } from 'react'

import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


const LogoMemo = React.memo(function Logo() {
    return (
        <>
            <div className="-m-1.5 p-1.5">
                <span className="sr-only">Reasonance</span>
                <img className="h-10 pr-2" src="logo_reasonance_transparent.png" alt="" />
            </div>
            <p className="my-auto font-bold text-xl text-secondary-700">Reasonance</p>
        </>
    )
}
);




export default function NavBar(props: any) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="px-6 py-3 lg:px-8 bg-white">
            <nav className="flex h-9 items-center justify-between" aria-label="Global">
                <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
                    <LogoMemo />
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-secondary-800/80"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:min-w-fit lg:flex-1 lg:justify-center lg:gap-x-12">
                    {props.content.map((item: any) => (
                        <a
                            key={item.name}
                            href={`#${item.link_to}`}
                            className="font-semibold text-secondary-800/80 hover:text-secondary-600/80">
                            {item.name}
                        </a>
                    ))}
                </div>
                <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
                    <a
                        href={`mailto:${props.contact_email}`}
                        className="inline-block rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 text-secondary-800 shadow-sm ring-1 ring-secondary-500/10 hover:ring-secondary-500/20"
                    >
                        Contact
                    </a>
                </div>
            </nav>
            <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-3 lg:hidden">
                    <div className="flex h-9 items-center justify-between">
                        <div className="flex">
                            <LogoMemo />
                        </div>
                        <div className="flex">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-secondary-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-secondary-500/10">
                            <div className="space-y-2 py-6">
                                {props.content.map((item: any) => (
                                    <a
                                        key={item.name}
                                        href={`#${item.link_to}`}
                                        onClick={() => setMobileMenuOpen(false)}
                                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-secondary-900 hover:bg-secondary-400/10"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <a
                                    href={`mailto:${props.contact_email}`}
                                    className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-secondary-900 hover:bg-secondary-400/10"
                                >
                                    Contact
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </div>)
}