export const testimonialsHeader = {
    surtitle: "Testimonials",
    title: "Why choose us?",
    description: ``,
    to_link: "testimonials"
}

export const testimonialsContent = [
    {
        name: "",
        role: "Chief Client Success Officer",
        company: "Nurole",
        review: `My structured sessions with Francois helped me to grow more self-assured. I learned to navigate my organisation more effectively and he helped me to work as a peer with my new leadership team, whilst supporting me to embrace new ways of thinking and acting that helped me to develop as a people leader. In addition to coaching, Francois is an excellent sounding board drawing on his years of experience in leadership himself`
    },
    {
        name: "Rutger Bruining",
        role: "CEO and Founder",
        company: "StoryTerrace",
        review: `Besides chairing the Board of StoryTerrace, François provides an invaluable sounding board on organisational development and people issues. Tapping into his experience allows me to make difficult decisions and move forward without delay.`
    },
    {
        name: "Ben Alun-Jones",
        role: "Co-Founder and CPO",
        company: "Unmade",
        review: `François coaching is really well structured, insightful and challenging and has pushed me to higher performance in a particularly difficult time for business. Unexpectedly the entire coaching program was done through zoom in the ‘new normal’, but François reacted to this with ease and ran an effective program that I would highly recommend.`
    },
    {
        name: "",
        role: "Product Director",
        company: "Babylon Health",
        review: `Before meeting François, I believed a decline in confidence was limiting me in my "role". Through a series of well structured sessions, François helped me understand what gives me confidence, what takes it away, and made me realise what actions I could take to stop undermining myself. François’ quickly created a safe and calm environment for us to dig into the topics deeply, and has given me the tools to continue practicing the learnings in everyday scenarios. He is generous with his advice, and truly knowledgeable in leadership.`
    },
    {
        name: "Ed Cooke",
        role: "Co-Founder",
        company: "Memrise",
        review: `François wears his great insight and operational experience in high growth businesses with empathy and intelligence; a natural listener, he drills quickly to the heart of issues and helps provide often creative ways to approach communication, decision making and prioritisation in a growing business.`
    },
    {
        name: "",
        role: "Vice-President",
        company: "eBay UK",
        review: `A short series of powerful coaching sessions – based on what was on my mind and beyond – helped to get to the nub of short term action with strategic intent.`
    },
    {
        name: "",
        role: "Commercial Director",
        company: "Deliveroo",
        review: `François’s operating experience coupled with his passion for people development through coaching has been instrumental when I built my team at Deliveroo. Very grateful for his support and teachings.`
    },
    {
        name: "",
        role: "Lead Product Manager",
        company: "Babylon Health",
        review: `François is a great coach and mentor. His extensive experience in the industry resulted in significant modifications in our teams in terms of strategy,  goal setting and ways of working. He was also great at helping me develop my soft skills, which led me to create a unique organisational identity and to communicate in a much more professional and effective manner.`
    }
]
