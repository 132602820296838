import ReactMarkdown from "react-markdown";
import remarkBreaks from 'remark-breaks'

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react"

export default function Testimonials(props: any) {
    const testimonials = props.content;

    const [reviewIndex, setReviewIndex] = useState<number>(0);

    function updateIndex(left: boolean) {
        let newIndex: number;
        if (left) {
            newIndex = reviewIndex - 1;
        } else {
            newIndex = reviewIndex + 1;
        }
        newIndex %= testimonials.length;

        if (newIndex < 0) {
            newIndex = testimonials.length - 1;
        } else if (newIndex === testimonials.length) {
            newIndex = 0;
        }
        console.log(newIndex);
        setReviewIndex(newIndex);
    }

    return (
        <div data-aos="fade-in">
            {/* section (group in a component) */}
            <svg className="h-12 mx-auto mt-20 text-gray-200" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
            </svg>
            {/* carousel component  */}
            <div className="mx-auto max-w-7xl -px-6 lg:px-8 flex">
                <button className="self-center" onClick={() => updateIndex(true)}>
                    <ArrowLeftIcon className="h-7 md:h-8 text-secondary-300" />
                </button>
                {/* card component */}
                <div className="px-4 md:px-20 py-5 mt-5">
                    <ReactMarkdown className="text-lg md:text-xl text-left md:text-center italic font-serif [&>p]:mt-5 [&>ul]:ml-3 [&>ul]:mt-2 [&>ul>li]:mt-1" remarkPlugins={[remarkBreaks]}>{testimonials[reviewIndex].review}</ReactMarkdown>
                    {testimonials[reviewIndex].name != "" ?
                        <p className="mt-4 text-left md:text-center text-sm">
                            <span className="font-semibold text-primary-500">{testimonials[reviewIndex].name}</span>
                            <span>{"  •  "}</span>
                            <span className="text-secondary-500">{testimonials[reviewIndex].role} at {testimonials[reviewIndex].company}</span>
                        </p> :
                        <p className="mt-4 text-left md:text-center text-sm">
                            <span className="font-semibold text-primary-500">{testimonials[reviewIndex].role}</span>
                            <span>{"  •  "}</span>
                            <span className="text-secondary-500">{testimonials[reviewIndex].company}</span>
                        </p>}
                </div>
                <button className="self-center" onClick={() => updateIndex(false)}>
                    <ArrowRightIcon className="h-7 md:h-8 text-secondary-300" />
                </button>
            </div>
        </div>
    )
}